import { useContext } from 'react'

import { Heading, Text, MediaQueries } from '@hhgtech/hhg-components'
import { StyledButton as MBButton } from 'components/atoms/button'
import { Container } from 'components/atoms/container'
import { NavigatorLayout } from 'components/templates/navigatorLayout'
import { domainLocales } from 'domainLocales'
import { useGetIsMobile } from 'hooks'
import { useScreenSize } from 'hooks/useScreenSize'
import { LOCALE_SPECS } from 'interfaces/constants'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { AppContext } from '../state/appContext'
import { State } from '../state/types'

const Styled404 = styled.div`
  .notFoundGrid {
    padding: 0 16px;
    margin: 64px auto 64px auto;
    button {
      border: 0;
      margin-top: 16px;
    }

    ${MediaQueries.mbUp} {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      margin: 128px auto 128px auto;
      height: calc(100vh - 365px);
    }

    ${MediaQueries.mbDown} {
      text-align: center;
    }

    .content {
      ${MediaQueries.mbDown} {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      h2 {
        margin-bottom: 16px;
        ${MediaQueries.mbDown} {
          margin-top: 16px;
          textalign: center;
        }
      }
      p {
        max-width: 400px;
      }
    }
  }
`

const Custom404 = () => {
  const { state = {} as State } = useContext(AppContext)
  const { isMobileSsr } = state
  const { isMobile: isMobileCsr } = useScreenSize()

  const isMobile = useGetIsMobile(isMobileSsr, isMobileCsr)
  const { formatMessage: f } = useIntl()

  const { locale } = useRouter()
  // const allTools = STATIC_PAGES[locale || defaultLocale] || {}
  const sL = locale || 'vi-VN' // standardized locale

  const domain = domainLocales[sL]

  const emailAddress = 'admin@' + domain

  return (
    <NavigatorLayout
      title={f({ id: '404.title' })}
      description={f({ id: '404.description' })}
      image={LOCALE_SPECS[sL].HOMEPAGE_IMAGE}
      isSearchBarDisplay
      SEOData={{
        SEOTitle: f({ id: '404.title' }),
        SEODescription: f({ id: '404.description' }),
        SEOImage: 'https://unsplash.it/600/600',
        hasNoAmp: true,
      }}
      isMobile={isMobile}
      dataNavMenuContent={null}
    >
      <Styled404>
        <Container className="notFoundGrid">
          <img
            loading="lazy"
            src="/images/notFound.jpg"
            style={{
              width: isMobile ? '100%' : '568px',
              height: isMobile ? '100%' : '319px',
            }}
          />
          <div className="content">
            <Heading tag="h2">
              {' '}
              {f({
                id: '404.pageNotFound',
                defaultMessage: 'Sorry, this page cannot be found',
              })}
            </Heading>
            <Text size="p3">
              <span
                dangerouslySetInnerHTML={{
                  __html: f(
                    {
                      id: '404.explainer',
                      defaultMessage:
                        'If you clicked a link that led to this page, please let us know so we can fix our mistake as soon as possible.',
                    },
                    {
                      email: `<a href="mailto:${emailAddress}">${emailAddress}</a>`,
                    },
                  ),
                }}
              />
            </Text>
            <a style={{ textDecoration: 'none' }} href="/">
              <MBButton size="md" color="primary">
                {f({
                  id: '404.goBackHome',
                  defaultMessage: 'Go Back Home',
                })}
              </MBButton>
            </a>
          </div>
        </Container>
      </Styled404>
    </NavigatorLayout>
  )
}

export default Custom404
